<template>
    <!-- :style="{ backgroundImage: 'url(' + dataTS.background + ')', backgroundSize: 'cover', display: 'block' }" -->
    <div class="home" :loading="isBusy" >
        <v-row class="my-0 mx-0">
            <v-col cols="12" sm="12" md="12" lg="6" class="mx-auto md-10 mb-md-10">
                <div class="login-box">
                    <v-carousel cycle height="100%" hide-delimiter-background show-arrows-on-hover>
                        <v-carousel-item v-for="(carousel, i) in items" :key="i" :src="carousel">
                            <template v-slot:placeholder>
                                <v-sheet color="grey lighten-4" class="fill-height">
                                    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                                </v-sheet>
                            </template>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" class="d-flex align-center flex-column">
                <div class="row">
                    <v-alert color="red darken-1" dark dismissible v-show="alert.Visible" v-text="alert.Message">
                    </v-alert>
                    <div class="form-box">
                        <div class="login-logo mb-7">
                            <img class="login-img" lazy-src="@/asremoves/images/logo-oaktree-full.png"
                                src="@/assets/images/logo-oaktree-full.png" />
                        </div>
                        <h1 class="text-center text--dark font-roboto mb-2">Hello Again!</h1>
                        <!-- <h2 class="text-center default--text font-inter mb-2">Enter your email and password to log in.</h2> -->
                        <p class="text-center default--text font-inter mb-7">
                            Enter your email and password to log in.
                        </p>
                        <form>
                            <div class="row">
                                <div class="col-md-12 py-0">
                                    <!-- <label>Email</label> -->
                                    <v-text-field autocomplete="email" class="d-block mt-2" v-model="authData.email"
                                        placeholder="Email" prepend-inner-icon="mdi-account" required outlined rounded
                                        @input="v$.authData.email.$touch()" @blur="v$.authData.email.$touch()">
                                    </v-text-field>
                                    <span class="error-message" v-if="v$.authData.email.$error"> {{
                                            v$.authData.email.$errors[0].$message
                                    }} </span>
                                </div>
                                <div class="col-md-12 py-0">
                                    <!-- <label>Password</label> -->
                                    <v-text-field autocomplete="new-password" class="d-block mt-2"
                                        placeholder="Password" v-model="authData.password" prepend-inner-icon="mdi-lock"
                                        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showpassword ? 'text' : 'password'" required outlined rounded
                                        @input="v$.authData.password.$touch()" @blur="v$.authData.password.$touch()"
                                        @click:append="showpassword = !showpassword">
                                    </v-text-field>
                                    <span class="error-message" v-if="v$.authData.password.$error"> {{
                                            v$.authData.password.$errors[0].$message
                                    }} </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 my-app">
                                    <v-checkbox v-model="remember" label="Remember me!"></v-checkbox>
                                </div>
                                <div class="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                    <v-btn text large @click.stop="goTo">
                                        <span :style="{ color : dataTS.button_color }">Recovery Password</span>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 py-0 mb-8 mt-8">
                                    <!-- :style="{ backgroundColor : dataTS.button_color }" -->
                                    <v-btn x-large depressed block color="bluelagoon shadow-bluelagoon" dark
                                        @click="submit" :loading="isBusy">
                                        Login</v-btn>
                                </div>
                            </div>
                        </form>
                        <p class="text-center text-small w-80 default--text font-roboto mt-5">An integrated Freight
                            Forwarding System that
                            helps to simplify all issues in one Platform.</p>
                    </div>
                </div>
                <div class="row align-center">
                    <FooterComp />
                </div>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import swal from 'sweetalert2';

export default {
    name: "LoginComp",
    components: {
        FooterComp: () => import("../Footer.vue")
    },

    mounted(){
        // console.log(, 'mount');
        // this.getLoginBanner();
        if (localStorage.getItem('emailRemember') !== null) {
            this.authData.email = localStorage.getItem('emailRemember'); // Atau halaman lain yang sesuai
            this.authData.password = localStorage.getItem('passRemember'); // Atau halaman lain yang sesuai
            this.remember = true
        }
    },

    setup() {
        return { v$: useVuelidate() }
    },

    data: () => ({
        items: [
            {
                src: require('@/assets/images/img-slider-1.png'),
            },
            {
                src: require("@/assets/images/bg-tracking-1.png"),
            },
            {
                src: require("@/assets/images/bg-tracking-2.png"),
            }
        ],
        token: localStorage.getItem('token'),
        authData: {
            email: "",
            password: "",
        }, 
        showpassword: false,
        remember: false,
        isBusy: false,
        alert: {
            Visible: "",
            Message: "",
        },
    }),

    validations() {
        return {
            authData: {
                email: {
                    required: helpers.withMessage('Email harus diisi.', required),
                    email: helpers.withMessage('Email harus valid.', email)
                },
                password: {
                    required: helpers.withMessage('Password harus diisi.', required)
                },
            }
        }
    },

    computed: {
        ...mapState('loginBanner', ["dataTS"]),
    },

    methods: {
        ...mapActions('loginBanner', ["getLoginBanner"]),
        ...mapActions('auth', ["login"]),
        
        submit() {
            this.isBusy = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.isBusy = false;
                console.log("err");
            } else {
                this.login(this.authData)
                    .then((res) => {
                        if(res.status === true){
                            this.swalResponse(res)
                            .then((res) => {
                                if(this.remember === true){
                                    localStorage.setItem('emailRemember', this.authData.email)
                                    localStorage.setItem('passRemember', this.authData.password)
                                } else {
                                    localStorage.removeItem('emailRemember', this.authData.email)
                                    localStorage.removeItem('passRemember', this.authData.password)
                                }
                                this.isBusy = false;
                                    this.$router.go("Dashboard");
                                })
                        } else {
                            this.swalResponse(res)
                            .then((res) => {
                                this.isBusy = false;
                                    // this.$router.replace("Dashboard");
                                })

                        }
                    })
                // this.auth();
                // this.$router.replace("Dashboard");
            }
        },
        goTo() {
            this.$router.replace("RecoveryPassword");
        },
        async swalResponse(res){
            // console.log(res);
            if(res.status === true){
                return new swal({
                    title: 'Success',
                    text: res.message,
                    icon: 'success', 
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#017283',
                    showLoaderOnConfirm: false,
                })
            } else {
                return new swal({
                    title: 'Failed',
                    // text: 'login failed',
                    text: res.message,
                    icon: 'error', 
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#017283',
                    showLoaderOnConfirm: false,
                })
            }
        }
    }
}
</script>
  