import axios from "axios";

export const state = {
  shipmentTracking: [],
};

export const mutations = {
  setShipmentTracking(state, payload) {
    state.shipmentTracking = payload;
  },

  setError(state, payload) {
    state.errors = payload;
  },
};

export const actions = {
  getShipmentTracking: async (context, payload, getters) => {
    try {

      let { data } = await axios.get(
        "/shipment-access-user/get-shipment-tracking?id=" + payload,
      );
      context.commit("setShipmentTracking", data);
      return data;

    } catch (error) {

      console.log(error);
      context.commit("setError", error);
      
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
