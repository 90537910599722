<template>
    <div class="home" :loading="isBusy">
        <v-row class="my-0 mx-0">
            <v-col cols="12" sm="12" md="12" lg="6" class="mx-auto md-10 mb-md-10">
                <div class="login-box">
                    <v-carousel cycle height="100%" hide-delimiter-background show-arrows-on-hover>
                        <v-carousel-item v-for="(carousel, i) in items" :key="i" :src="carousel">
                            <template v-slot:placeholder>
                                <v-sheet color="grey lighten-4" class="fill-height">
                                    <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                                </v-sheet>
                            </template>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" class="d-flex align-center flex-column">
                <div class="row">
                    <v-alert color="red darken-1" dark dismissible v-show="alert.Visible" v-text="alert.Message">
                    </v-alert>
                    <div class="form-box">
                        <div class="login-logo mb-7">
                            <img class="login-img" lazy-src="@/assets/images/logo-oaktree-full.png"
                                src="@/assets/images/logo-oaktree-full.png" />
                        </div>
                        <h1 class="text-center text--dark font-roboto mb-2">Activate Your Account</h1>
                        <!-- <h2 class="text-center default--text font-inter mb-2">Enter your email and password to log in.</h2> -->
                        <p class="text-center default--text font-inter w-80 mb-7">
                            Create password to activate your account
                        </p>
                        <form>
                            <div class="row">
                                <div class="col-md-12 py-0">
                                    <v-text-field autocomplete="passwords" class="d-block mt-2" v-model="verification.password"
                                        placeholder="Input New Password" prepend-inner-icon="mdi-lock"
                                        :append-icon="showChangePassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showChangePassword ? 'text' : 'password'" required outlined rounded
                                        @input="v$.verification.password.$touch()" @blur="v$.verification.password.$touch()"
                                        @click:append="showChangePassword = !showChangePassword">
                                    </v-text-field>
                                    <span class="error-message" v-if="v$.verification.password.$error"> {{
                                            v$.verification.password.$errors[0].$message
                                    }}
                                    </span>
                                </div>
                                <div class="col-md-12 py-0">
                                    <v-text-field autocomplete="cpasswords" class="d-block mt-2" v-model="verification.cpassword"
                                        placeholder="Confirm New Password" prepend-inner-icon="mdi-lock"
                                        :append-icon="showChangeCpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showChangeCpassword ? 'text' : 'password'" required outlined rounded
                                        @input="v$.verification.cpassword.$touch()"
                                        @click:append="showChangeCpassword = !showChangeCpassword"
                                        @blur="v$.verification.cpassword.$touch()"></v-text-field>
                                    <span class="error-message" v-if="v$.verification.cpassword.$error">
                                        {{ v$.verification.cpassword.$errors[0].$message }}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-12 col-sm-12 col-md-6 col-lg-6 mx-auto d-flex align-center justify-center">
                                    <p class="my-0">Already have an Account? </p>
                                    <v-btn text large @click.stop="goTo" color="bluelagoon"
                                        style="margin:0px;padding:0px;height:0px;font-size:16px;min-width: unset;margin-left: 8px;">
                                        Login
                                    </v-btn>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 py-0 mb-8 mt-8">
                                    <v-btn x-large depressed block color="bluelagoon shadow-bluelagoon" dark
                                        @click="submit()" :loading="isBusy">
                                        Create New Password</v-btn>
                                </div>
                            </div>
                        </form>
                        <p class="text-center text-small w-80 default--text font-roboto mt-5">An integrated Freight
                            Forwarding System that
                            helps to simplify all issues in one Platform.</p>
                    </div>
                </div>
                <div class="row align-center">
                    <FooterComp />
                </div>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import { useVuelidate } from '@vuelidate/core';
import { sameAs, required, helpers, minLength } from '@vuelidate/validators';
import swal from 'sweetalert2';

export default {
    name: "ActivateAccountComp",
    components: {
        FooterComp: () => import("../Footer.vue")
    },

    setup() {
        return { v$: useVuelidate() }
    },

    mounted(){
        // this.getLoginBanner();
    },

    data() {
        return {
            items: [
                {
                    src: require('@/assets/images/img-slider-1.png'),
                },
                {
                    src: require('@/assets/images/img-slider-2.png'),
                },
                {
                    src: require('@/assets/images/img-slider-3.png'),
                }
            ],
            showChangePassword: false,
            showChangeCpassword: false,
            isBusy: false,
            alert: {
                Visible: "",
                Message: "",
            },
            verification: {
                email: '',
                token: '',
                password: "",
                cpassword: "",
            },
        }
    },

    created() {
        this.verification.email = this.$route.query.email;
        this.verification.token = this.$route.query.token;
    },

    validations() {
        return{
            verification: {
                password: {
                    required: helpers.withMessage('Password harus diisi.', required),
                    minLength: helpers.withMessage('Password minimal 6 karakter.', minLength(6))
                },
                cpassword: {
                    required: helpers.withMessage('Konfirmasi Password harus diisi.', required),
                    sameAsPassword: helpers.withMessage('Konfirmasi Password harus sesuai.', sameAs(this.verification.password)),
                },
            }
        }
    },

    computed: {
        ...mapState('loginBanner', ["dataTS"]),
    },

    methods: {
        // load actions
        ...mapActions('shipmentAccessUser', ["setShipmentAccessUserPassword"]),
        ...mapActions('loginBanner', ["getLoginBanner"]),


        submit() {
            this.isBusy = true;
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.isBusy = false;
            } else {
                new swal({
                    title: 'Are you sure you want to save it?',
                    text: '',
                    // icon: 'success',                    
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    // timer: 1500,
                    showLoaderOnConfirm: false,
                    customClass: {
                        container: 'swal-container-oaktree',
                        popup: 'swal-popup-oaktree',
                        header: 'swal-header-oaktree',
                        title: 'swal-title-oaktree',
                        icon: 'swal-icon-success-oaktree',
                        image: 'swal-image-oaktree',
                        content: 'swal-content-oaktree',
                        input: 'swal-input-oaktree',
                        actions: 'swal-actions-oaktree',
                        confirmButton: 'swal-confirm-button-oaktree',
                        denyButton: 'swal-confirm-button-oaktree',
                        cancelButton: 'swal-cancel-button-oaktree',
                        footer: 'swal-footer-oaktree'
                    }
                }).then((result) => {
                    if (result.value == true) {
                        this.setShipmentAccessUserPassword(this.verification)
                            .then((res) => {
                                if(res.data.status === true){
                                    this.swalResponse(res.data.status)
                                        .then((res) => {
                                            this.isBusy = false;
                                            this.$router.replace("/");
                                        })
                                }
                            })
                    } else {
                        this.isBusy = false;
                    }
                });
            }
        },
        goTo() {
            this.$router.replace("/");
        },
        async swalResponse(status){
            if(status === true){
                return new swal({
                    title: 'Success',
                    text: 'Change password success',
                    icon: 'success', 
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#017283',
                    showLoaderOnConfirm: false,
                })
            }
        }
    }
}
</script>
  