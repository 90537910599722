<template>
  <section>
    <!-- <template v-if="typeRange === 'annualy'">
        <apexchart
          type="area"
          :options="optionsAnnualy"
          :series="seriesAnnualy"
          id="job-statistic-annualy"
          height="300"
        >
        </apexchart>
      </template>
      <template v-else>
        <apexchart
          type="area"
          :options="optionsMonthly"
          :series="seriesMonthly"
          id="job-statistic-monthly"
          height="300"
        >
        </apexchart>
      </template> -->
    <template>
      <section>
        <template v-if="typeRange === 'monthly'">
          <apexchart
            type="area"
            :options="optionsMonthly"
            :series="seriesMonthly"
            id="job-statistic-monthly"
            height="300"
          >
          </apexchart>
        </template>
        <template v-else>
          <apexchart
            type="area"
            :options="optionsAnnualy"
            :series="seriesAnnualy"
            id="job-statistic-annualy"
            height="300"
          >
          </apexchart>
        </template>
        <template v-else>
          <apexchart
            type="area"
            :options="optionsMonthly"
            :series="seriesMonthly"
            id="job-statistic-monthly"
            height="300"
          >
          </apexchart>
        </template>
      </section>
    </template>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    typeRange: String,
    rangeYear: Number,
    rangeMonth: String,
  },
  data() {
    return {
      isLoading: false,
      // options untuk annualy
      optionsAnnualy: {
        chart: {
          zoom: {
            enabled: false, // Menonaktifkan kemampuan zoom
          },
          toolbar: {
            show: false,
          },

          id: "job-statistic-annualy",
        },

        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          min: 0,
          max: 150,
          tickAmount: 5,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
            step: 30, 
          },
        },
      },
      // options untuk monthly
      optionsMonthly: {
        chart: {
          zoom: {
            enabled: false, // Menonaktifkan kemampuan zoom
          },
          toolbar: {
            show: false,
          },
          id: "job-statistic-monthly",
        },
        xaxis: {
          categories: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
            "31",
          ],
        },
        yaxis: {
          min: 0,
          max: 150,
          tickAmount: 5,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
            step: 30, 
          },
        },
      },
      // series data untuk annualy
      seriesAnnualy: [
        {
          name: "job-statistic-annualy",
          data: [60, 32, 56, 41, 77, 22, 90, 100, 80, 60, 96, 139],
        },
      ],
      // series data untuk monthly
      seriesMonthly: [
        {
          name: "job-statistic-monthly",
          data: [55, 62, 89, 66, 98, 72, 101, 75, 94, 120, 117, 160],
        },
      ],
    };
  },

  computed: {
    ...mapState(["userLogin"]),
  },

  mounted() {
    this.fetchDataOrderStatic();
  },

  watch: {
    rangeYear(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataOrderStatic();
      }
    },

    rangeMonth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataOrderStatic();
      }
    },

    typeRange(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataOrderStatic();
      }
    },
  },

  methods: {
    fetchDataOrderStatic() {
      if (this.isLoading) return;
      this.isLoading = true;
      const endPointOrderStatic = `/dashboard-tracking/get-job-order-static?id=${this.$store.state.userLogin.id}&rangeYear=${this.rangeYear}`;

      if (this.typeRange === "annualy") {
        axios.get(endPointOrderStatic).then((res) => {
          const monthlyData = res.data;
          const dataArray = Object.entries(monthlyData)
            .sort(([monthA], [monthB]) => parseInt(monthA) - parseInt(monthB))
            .map(([, value]) => value);
          this.seriesAnnualy = [
            {
              name: "job-statistic-annualy",
              data: dataArray,
            },
          ];
          this.isLoading = false;
          // console.log("ini annualy", dataArray);
        });
      } else if (this.typeRange === "monthly") {
        const monthlyUrl = `${endPointOrderStatic}&month=${this.rangeMonth}`;
        axios.get(monthlyUrl).then((res) => {
          const dailyData = res.data;
          const dataArray = Object.entries(dailyData)
            .sort(([monthA], [monthB]) => parseInt(monthA) - parseInt(monthB))
            .map(([, value]) => value);
          this.seriesMonthly = [
            {
              name: "job-statistic-monthly",
              data: dataArray,
            },
          ];
          this.isLoading = false;
          // console.log("harian", monthlyUrl);
        });
      }
    },
  },
};
</script>

<style></style>
