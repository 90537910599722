<template>
  <section class="position-relative">
    <div class="position-absolute top-50 start-50 translate-middle">
      <span class="text-summary finished font-roboto">Tidak ada data</span>
    </div>
    <div class="position-absolute top-0 start-0 text-center">
      <span class="text-summary finished font-roboto"
        >Finished <br />{{ dataSummary.finished }}%</span
      >
    </div>
    <div class="position-absolute top-0 end-0 text-center">
      <span class="text-summary neworder font-roboto"
        >Delivery <br />{{ dataSummary.delivery }}%</span
      >
    </div>
    <div class="position-absolute bottom-0 start-0 text-center">
      <span class="text-summary delivery font-roboto">
        New Job<br />{{ dataSummary.newOrder }}%</span
      >
    </div>
    <div class="my-auto">
      <apexchart
        type="pie"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  props: {
    typeRange: String,
    rangeYear: {
      type: Number,
    },
    rangeMonth: String,
  },
  data() {
    return {
      isLoading: false,
      dataSummary: {
        newOrder: 0,
        delivery: 0,
        finished: 0,
      },
      series: [44, 55, 13],
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: "pie",
          height: "auto",
        },
        labels: ["Delivery", "New Job", "Finished"],
        legend: {
          show: false,
          position: "bottom",
          onItemClick: {
            toggleDataSeries: false, // Menonaktifkan tindakan klik pada legenda
          },
        },
        plotOptions: {
          pie: {
            offsetY: 0,
            offsetX: 0,
          },
        },
        responsive: [
          {
            breakpoint: 700,
            options: {
              chart: {
                type: "pie",
                height: "auto",
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  computed: {
    ...mapState(["userLogin"]),
  },

  watch: {
    rangeYear(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataTotalDevilery();
      }
    },

    rangeMonth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataTotalDevilery();
      }
    },

    typeRange(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchDataTotalDevilery();
      }
    },
  },

  mounted() {
    this.fetchDataTotalDevilery();
  },

  methods: {
    fetchDataTotalDevilery() {
      if (this.isLoading) return;
      this.isLoading = true;
      const endPointTotalDevilery = `/dashboard-tracking/get-total-delivery?id=${this.$store.state.userLogin.id}&rangeYear=${this.rangeYear}`;
      if (this.typeRange === "annualy") {
        axios
          .get(endPointTotalDevilery)
          .then((res) => {
            this.series = [
              res.data.in_progress, //Delivery
              res.data.new_delivery, //New Job
              res.data.finished, //Finished
            ];

            if (res.data.total_data === 0) {
              this.dataSummary.finished = 0;
              this.dataSummary.delivery = 0;
              this.dataSummary.newOrder = 0;
            } else {
              this.dataSummary.finished = (
                (res.data.finished / res.data.total_data) *
                100
              ).toFixed(2);
              this.dataSummary.delivery = (
                (res.data.in_progress / res.data.total_data) *
                100
              ).toFixed(2);
              this.dataSummary.newOrder = (
                (res.data.new_delivery / res.data.total_data) *
                100
              ).toFixed(2);
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.error("Error fetching data total devilery:", err);
          });
      } else if (this.typeRange === "monthly") {
        const monthlyUrl = `${endPointTotalDevilery}&month=${this.rangeMonth}`;
        axios
          .get(monthlyUrl)
          .then((res) => {
            this.series = [
              res.data.in_progress, //Delivery
              res.data.new_delivery, //New Job
              res.data.finished, //Finished
            ];

            if (res.data.total_data === 0) {
              this.dataSummary.finished = 0;
              this.dataSummary.delivery = 0;
              this.dataSummary.newOrder = 0;
            } else {
              this.dataSummary.finished = (
                (res.data.finished / res.data.total_data) *
                100
              ).toFixed(2);
              this.dataSummary.delivery = (
                (res.data.in_progress / res.data.total_data) *
                100
              ).toFixed(2);
              this.dataSummary.newOrder = (
                (res.data.new_delivery / res.data.total_data) *
                100
              ).toFixed(2);
            }
            this.isLoading = false;
          })
          .catch((err) => {
            console.error("Error fetching data total devilery:", err);
          });
      }
    },

    // fetchDataTotalDevilery() {
    //   axios
    //     .get(
    //       `/dashboard-tracking/get-total-delivery?id=${this.$store.state.userLogin.id}`
    //     )
    //     .then((res) => {
    //       this.series = [
    //         res.data.in_progress, //Delivery
    //         res.data.new_delivery, //New Job
    //         res.data.finished, //Finished
    //       ];
    //       this.dataSummary.finished = (
    //         (res.data.finished / res.data.total_data) *
    //         100
    //       ).toFixed(2);
    //       this.dataSummary.delivery = (
    //         (res.data.in_progress / res.data.total_data) *
    //         100
    //       ).toFixed(2);
    //       this.dataSummary.newOrder = (
    //         (res.data.new_delivery / res.data.total_data) *
    //         100
    //       ).toFixed(2);

    //       console.log(this.series);
    //     })
    //     .catch((err) => {
    //       console.error("Error fetching data total devilery:", err);
    //     });
    // },
  },
};
</script>

<style scoped>
.position-absolute.top-0.start-0,
.position-absolute.top-0.end-0,
.position-absolute.bottom-0.start-0 {
  padding: 20px; /* Sesuaikan dengan jarak yang Anda inginkan */
}

.text-summary {
  font-size: 12px;
  font-weight: 400;
}

.text-summary.delivery {
  color: #00e396;
}
.text-summary.neworder {
  color: #008ffb;
}
.text-summary.finished {
  color: #feb019;
}
</style>
