import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import RecoveryPassword from "../views/RecoveryPassword.vue";
import ActivateAccount from "../views/ActivateAccount.vue";
import ChangePassword from "../views/ChangePassword.vue";
import Profile from "../views/Profile/Profile.vue";
import ProfileEdit from "../views/Profile/ProfileEdit.vue";
import Tracking from "../views/Tracking.vue"
import DetailTracking from "../views/DetailTracking.vue"


Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "notFound",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      guest: true
    }
  },
  {
    path: "/recoverypassword",
    name: "RecoveryPassword",
    component: RecoveryPassword,
    meta: {
      requiresAuth: false,
      guest: true
    }
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      requiresAuth: false,
      guest: true
    }
  },
  {
    path: "/activate-account",
    name: "ActivateAccount",
    component: ActivateAccount,
    meta: {
      requiresAuth: false,
      guest: true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile/edit",
    name: "ProfileEdit",
    component: ProfileEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tracking",
    name: "Tracking",
    component: Tracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tracking/:jobfileId",
    name: "DetailTracking",
    component: DetailTracking,
    meta: {
      requiresAuth: true,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

router.beforeEach(async (to, from, next) => {
  const authToken = localStorage.getItem('token');
  if(localStorage.getItem('auth.currentUser') !== 'undefined'){
    var user = JSON.parse(localStorage.getItem('auth.currentUser'));
  }
  // console.log(authToken,'token');
  // const authRequired = to.matched.some(record => record.meta.requiresAuth)
  // const isAuthenticated = store.getters['auth/isLoggedIn'];
  
  
  if(!user){
    if (!authToken && to.meta.requiresAuth) {
      next({
        path: "/login",
      });
    } else if(to.name === 'notFound'){
      next('login')
    }
    else {
      next();
    }
  } else {
    next();
  }
})
router.beforeResolve((to, from, next) => {
  const authToken = localStorage.getItem('token');
  if(localStorage.getItem('auth.currentUser') !== 'undefined'){
    var user = JSON.parse(localStorage.getItem('auth.currentUser'));
  }
  if(user){
    if(authToken === user.token_login && to.meta.guest === true){
      next('dashboard');
    } else {
      if(to.name === 'notFound'){
        next('dashboard')
      }
      next()
    }
  } else {
    next()
  }
})


export default router;
