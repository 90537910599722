<template>
    <!-- <div class="widget-page">
        <div class="container-parent"> -->
            <div class="row container mx-auto row bg-white rounded  mt-7 mb-2">
                <div class="col-lg-11">
                    <label class="menu-label-inside" for="">Email Notification</label>
                </div>
                <div class="col-lg-1 float-right">
                    <toggle-button
                    v-model="emailActive"
                    :sync="true"
                    color="#ffebb8" :switch-color="{
                            checked: '#f9a826',
                            unchecked: '#eff0f0'
                        }" @change="changeActive()"/>
                </div>
            </div>
        <!-- </div>
    </div> -->
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import swal from 'sweetalert2';

    export default{
        name: 'EmailComp',

        data() {
            return{
                emailActive: false,
                color: {
                    checked: '#f9a826',
                    unchecked: 'fffff'
                },
            }
        },

        mounted(){
            this.getUser(this.userLogin.id)
                .then((res) => {
                    let notif = res.data.email_notif === 1 ? true : false
                    this.emailActive = notif
                })
        },

        computed: {
            ...mapState(["userLogin"]),
        },

        
        methods: {
            ...mapActions('profile', ['emailNotif']),
            ...mapActions('shipmentAccessUser', ["getUser"]),

            changeActive() {
                let data = {
                    id: this.userLogin.id,
                    active: this.emailActive
                }

                this.emailNotif(data)
                    .then((res) => {
                        this.swalResponse(res)
                    })
            },

            async swalResponse(res){
                if(res.status === true){
                    return new swal({
                        title: 'Success',
                        text: res.message,
                        icon: 'success', 
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        confirmButtonColor: '#017283',
                        showLoaderOnConfirm: false,
                    })
                        .then((res) => {
                            // this.$router.go()
                        })
                } else {
                    return new swal({
                        title: 'Failed',
                        text: 'failed',
                        icon: 'error', 
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                        confirmButtonColor: '#017283',
                        showLoaderOnConfirm: false,
                    })
                }
            }
        }
    }

</script>

<style scoped>
    .widget-page{
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .container-parent{
        margin: 30px 0px;
        /* display: flex; */
        width: 95%;
        background-color: white;
    }
    .container-child{
        width: 100%;
    }

    @media screen and (max-width: 988px) {
        .container > div:nth-child(1){
            width: 80%;
        }
        .container > div:nth-child(2){
            width: 20%;
        }
    }
</style>
