<template>
    <div class="home" :loading="isBusy">
        <v-row class="my-0 mx-0">
            <v-col cols="12" sm="12" md="12" lg="6" class="mx-auto md-10 mb-md-10">
                <div class="login-box">
                    <v-carousel cycle height="100%" hide-delimiter-background show-arrows-on-hover>
                        <v-carousel-item v-for="(carousel, i) in items" :key="i" :src="carousel">
                            <template v-slot:placeholder>
                                <v-sheet color="grey lighten-4" class="fill-height">
                                    <v-skeleton-loader class="mx-auto" type="image" height="100%"></v-skeleton-loader>
                                </v-sheet>
                            </template>
                        </v-carousel-item>
                    </v-carousel>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" class="d-flex align-center flex-column">
                <div class="row">
                    <v-alert color="red darken-1" dark dismissible v-show="alert.Visible" v-text="alert.Message">
                    </v-alert>
                    <div class="form-box">
                        <div class="login-logo mb-7">
                            <img class="login-img" lazy-src="@/assets/images/logo-oaktree-full.png"
                                src="@/assets/images/logo-oaktree-full.png" />
                        </div>
                        <h1 class="text-center text--dark font-roboto mb-2">Recovery Your Password</h1>
                        <!-- <h2 class="text-center default--text font-inter mb-2">Enter your email and password to log in.</h2> -->
                        <p class="text-center default--text font-inter w-80 mb-7">
                            We’ll send you a verification code to your registered email to reset your password
                        </p>
                        <form>
                            <div class="row">
                                <div class="col-md-12 pt-0 pb-10">
                                    <!-- <label>Email</label> -->
                                    <v-text-field autocomplete="email" class="d-block mt-2" v-model="email"
                                        placeholder="Email" prepend-inner-icon="mdi-account" required outlined rounded
                                        @input="v$.email.$touch()" @blur="v$.email.$touch()">
                                    </v-text-field>
                                    <span class="error-message" v-if="v$.email.$error"> {{
                                            v$.email.$errors[0].$message
                                    }} </span>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="col-12 col-sm-12 col-md-6 col-lg-6 mx-auto d-flex align-center justify-center">
                                    <p class="my-0">Already have an Account? </p>
                                    <v-btn text large @click.stop="goTo" color="bluelagoon"
                                        style="margin:0px;padding:0px;height:0px;font-size:16px;min-width: unset;margin-left: 8px;">
                                        <span>Login</span>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 py-0 mb-8 mt-8">
                                    <v-btn x-large depressed block color="bluelagoon shadow-bluelagoon" dark
                                        @click="submit()" :loading="isBusy">
                                        Send Verification</v-btn>
                                </div>
                            </div>
                        </form>
                        <p class="text-center text-small w-80 default--text font-roboto mt-5">An integrated Freight
                            Forwarding System that
                            helps to simplify all issues in one Platform.</p>
                    </div>
                </div>
                <div class="row align-center">
                    <FooterComp />
                </div>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
import { mapActions, mapState } from "vuex";
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import swal from 'sweetalert2';

export default {
    name: "RecoveryPasswordComp",
    components: {
        FooterComp: () => import("../Footer.vue")
    },

    mounted(){
        // this.getLoginBanner();
    },

    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {
            items: [
            {
                src: require('@/assets/images/img-slider-1.png'),
            },
            {
                src: require('@/assets/images/img-slider-2.png'),
            },
            {
                src: require('@/assets/images/img-slider-3.png'),
            }
        ],
            email: "",
            isBusy: false,
            alert: {
                Visible: "",
                Message: "",
            }
        }
    },

    validations: {
        email: {
            required: helpers.withMessage('Email harus diisi.', required),
            email: helpers.withMessage('Email harus valid.', email)
        },
    },

    computed:{
        // ...mapState('loginBanner', ["dataTS"]),
    },

    methods: {
        // ...mapActions('loginBanner', ["getLoginBanner"]),
        ...mapActions('auth', ["recoverPassword"]),

        submit() {            
            this.v$.$touch();
            if (this.v$.$invalid) {
                this.isBusy = false;
                console.log("err");
            } else {
                new swal({
                    title: 'Are you sure you want to send it?',
                    text: '',
                    // icon: 'success',                    
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    // timer: 1500,
                    showLoaderOnConfirm: false,
                    customClass: {
                        container: 'swal-container-oaktree',
                        popup: 'swal-popup-oaktree',
                        header: 'swal-header-oaktree',
                        title: 'swal-title-oaktree',
                        icon: 'swal-icon-success-oaktree',
                        image: 'swal-image-oaktree',
                        content: 'swal-content-oaktree',
                        input: 'swal-input-oaktree',
                        actions: 'swal-actions-oaktree',
                        confirmButton: 'swal-confirm-button-oaktree',
                        denyButton: 'swal-confirm-button-oaktree',
                        cancelButton: 'swal-cancel-button-oaktree',
                        footer: 'swal-footer-oaktree'
                    }
                }).then((result) => {
                    // console.log(result.value, 'passed');
                    if (result.value == true) {
                        this.recoverPassword(this.email)
                            .then((res) => {
                                this.swalResponse(true)
                                    .then((res) => {
                                        this.isBusy = false;
                                        this.$router.replace("/");
                                    })
                            })
                    } else if (result.value == false) {
                        this.isBusy = false;
                    }
                });
            }
        },
        goTo() {
            this.$router.replace("/");
        },
        async swalResponse(status){
            if(status === true){
                return new swal({
                    title: 'Success',
                    text: 'Please check your email',
                    icon: 'success', 
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
                    confirmButtonColor: '#017283',
                    showLoaderOnConfirm: false,
                })
            }
        }
    }
}
</script>
  