import Vue from 'vue'
// import VueSweetalert2 from 'vue-sweetalert2'
import Swal from 'sweetalert2'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'
import './config/Api'
// import PortalVue from 'portal-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'sweetalert2/src/sweetalert2.scss'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
// import 'jquery/src/jquery.js'
import 'bootstrap';
// import 'bootstrap/scss/bootstrap.scss';
import Select2 from 'v-select2-component';
import ToggleButton from 'vue-js-toggle-button'
import '@/plugins/apexcharts'

// import 'bootstrap/dist/js/bootstrap.min.js'

// var $ = require('jquery')

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('Select2', Select2);
Vue.use(ToggleButton)

// Vue.prototype.$userLogin = 'tes'
// Vue.use(PortalVue)
// Vue.use(Swal)

// import Swal from 'sweetalert2';
// window.Swal = Swal;

export default new Vue({
  vuetify,
  Swal,
  router,
  axios,
  store,

  async created() {
    window.axios = axios
		// axios.defaults.baseURL = process.env.VUE_APP_ROOT_API
    // console.log(,'tokne')
    if(localStorage.getItem('token')){
			axios.defaults.headers.common = {
				"Authorization": "Bearer " + localStorage.getItem("token"),
			};
    }

    
  },
  render: h => h(App)
}).$mount('#app')
