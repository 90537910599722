<template>
  <section class="container p-0 mx-auto">
    <div class="col-sm-12 tracking-info rounded">
      <div
        class="d-flex flex-row justify-content-between align-items-center px-4 py-2"
      >
        <p class="font-roboto title">Tracking Info</p>
        <button
          class="btn btn-outline-warning border-0 rounded font-roboto px-3"
          @click="refreshTrackingInfo"
        >
          <i class="bi bi-arrow-repeat me-2"></i>Refresh
        </button>
      </div>
    </div>
    <div class="col-sm-12 py-4 min-height-100 position-relative">
      <template v-if="localTrackingInfo && localTrackingInfo.shipment_timeline">
        <template
          v-for="(timeLine, index) in localTrackingInfo.shipment_timeline
            .slice()
            .reverse()"
        >
          <Timeline
            :key="index"
            :date="formatDate(timeLine.created_at)"
            :status="timeLine.status_timeline.type"
            :title="timeLine.status_timeline.title"
            :desc="timeLine.description"
            :numberOrder="timeLine.numberOrder"
            :location="
              timeLine.port
                ? `${timeLine.port.desc_port},${timeLine.port.country_port}`
                : null
            "
            :time="formatTime(timeLine.created_at)"
          />
        </template>
      </template>
      <template>
        <Timeline
          :date="'Proses Penjadwalan'"
          :status="0"
          :title="'Proses Penjadwalan'"
          :desc="''"
          :location="null"
        />
      </template>
      <div class="timeline-container position-absolute">
        <div class="timeline position-absolute"></div>
      </div>
    </div>

    <!-- <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      class="btn btn-secondary px-4 py-2 font-roboto"
    >
      Back
    </button> -->

    <button @click="goBack" class="btn btn-secondary px-4 py-2 font-roboto">
      Back
    </button>
  </section>
</template>

<script>
import moment from "moment";
import Timeline from "@/components/Tracking/Timeline.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { Timeline },
  data() {
    return {
      timeLines: [
        {
          numberOrder: 10,
          status: 0,
          title: "Proses Penjadwalan",
          desc: "Proses Penjadwalan",
          date: "Proses Penjadwalan",
          location: "",
        },
      ],
      localTrackingInfo: [],
    };
  },

  mounted() {
    this.getData();
  },

  computed: {},

  methods: {
    ...mapActions("shipmentTrackingInfo", ["getShipmentTrackingInfo"]),

    goBack() {
      history.back();
    },

    getData() {
      this.getShipmentTrackingInfo(this.$route.params.jobfileId).then((res) => {
        this.localTrackingInfo = res;
        // console.log("Tracking info", this.localTrackingInfo.shipment_timeline);
      });
    },

    async refreshTrackingInfo() {
      this.localTrackingInfo = [];
      await this.getData();
      this.$emit("refresh-request");
    },

    formatDate(date) {
      const formatted = moment(date).format("DD MMM YYYY");
      return formatted;
    },

    formatTime(date) {
      // const localizedDate = moment.utc(date).local();
      // const formatted = localizedDate.format("HH:mm");
      // return formatted;
      const formatted = moment(date).format("HH:mm");
      return formatted;
    },
  },
};
</script>

<style>
.timeline-container {
  top: 20px;
  bottom: 20px;
}

.min-height-100 {
  min-height: 200px;
}

.btn-secondary {
  background-color: #b3b3b3;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
  color: #ffffff;
}
.btn-secondary:hover {
  border: transparent;
  opacity: 0.8;
  background-color: #b3b3b3;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
}

.font-roboto.title {
  font-weight: 700;
  font-size: 16px;
  color: #585858;
}
.tracking-info {
  background: #f8f8f8;
}
button.btn-outline-warning {
  color: #f9a707;
  background-color: transparent;
  font-weight: 500;
  font-size: 1rem;
}
button.btn-outline-warning:hover {
  color: #f9a707;
  background-color: transparent;
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.5;
}

.timeline {
  width: 4px;
  height: 100%;
  background-color: #dbe8e5;
}

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .timeline {
    left: 118px;
  }
} */

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {
  .timeline {
    left: 183px;
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {
  .timeline {
    left: 138px;
  }
} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
  .timeline {
    left: 118px;
  }
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {
  .timeline {
    left: 160px;
  }
} */

/* @media only screen and (max-width: 600px) {
  .timeline {
    left: 105px;
  }
}

@media only screen and (min-width: 600px) {
  .timeline {
    left: 135px;
  }
} */

@media (max-width: 480px) {
  .timeline {
    left: 104px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .timeline {
    left: 138px;
  }
}

/* @media only screen and (min-width: 768px) {
  .timeline {
    left: 145px;
  }
} */
@media (min-width: 768px) and (max-width: 1023px) {
  .timeline {
    left: 135px;
  }
}

/* @media only screen and (min-width: 992px) {
  .timeline {
    left: 108px;
  }
} */

@media (min-width: 1024px) and (max-width: 1279px) {
  .timeline {
    left: 115px;
  }
}

@media only screen and (min-width: 1280px) {
  .timeline {
    left: 145px;
  }
}
</style>
