<template>
  <section class="container-fluid d-flex flex-column">
    <Header />
    <span class="mb-4"></span>
    <MenuGroup activeMenu="tracking" />
    <!-- TABLES -->
    <div
      class="container mx-auto p-0 row bg-white px-2 px-md-4 py-3 py-md-4 gap-4 mt-4 mt-md-0"
    >
      <div
        class="col-sm-12 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center gap-2 gap-md-0"
      >
        <h6 class="title-tracking font-roboto">List AWB or B/L Number</h6>
        <div class="d-flex flex-column flex-md-row gap-2">
          <!-- <select
            class="form-select range-date pe-5"
            aria-label="Default select example"
            v-model="rangeMonth"
          >
            <template v-for="(opRangeMonth, index) in optionsRangeMonth">
              <option
                :value="opRangeMonth.value"
                :key="opRangeMonth.value + '_month' + index"
              >
                {{ opRangeMonth.text }}
              </option>
            </template>
          </select> -->
          <select
            class="form-select range-date"
            aria-label="Default select example"
            v-model="selectedMonth"
            @change="filterData"
          >
            <option value="">Pilih Bulan</option>
            <option
              v-for="(month, index) in etaData"
              :key="index"
              :value="month"
            >
              {{ month }}
            </option>
          </select>
          <!-- <select
            class="form-select range-date pe-5"
            aria-label="Default select example"
            v-model="rangeYear"
          >
            <template v-for="opRangeYear in optionsRangeYear">
              <option
                :value="opRangeYear.value"
                :key="opRangeYear.value + 'year_monthly'"
              >
                {{ opRangeYear.text }}
              </option>
            </template>
          </select> -->
          <select
            class="form-select range-date"
            aria-label="Default select example"
            v-model="selectedYear"
            @change="filterData"
          >
            <option value="">Pilih Tahun</option>
            <option
              v-for="(year, index) in yearData"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <div class="input-group border rounded search-tracking">
            <span
              class="input-group-text bg-transparent border-0 ps-3"
              id="search_tracking"
            >
              <img src="@/assets/images/search-icon.svg" class="img-fluid" />
            </span>
            <input
              type="text"
              class="form-control border-0"
              placeholder="search"
              v-model="searchQuery"
              @input="applySearch"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-12 d-flex flex-column gap-4">
        <template v-if="!this.shipmentDataTracking.length">
          <p class="font-roboto text-center text-muted">
            Data yang anda cari tidak ada, silahkan cek kata kunci dengan baik
          </p>
        </template>
        <template v-else>
          <template v-for="dTracking in shipmentDataTracking">
            <DeliveryOrder
              :key="dTracking.id"
              :id_sea ="dTracking.id_sea"
              :id_air = "dTracking.id_air"
              :jobNumber="dTracking.jobfile_no"
              :blNumber="dTracking.bl_number"
              :hawbNumber="dTracking.hawb_number"
              :vessel="dTracking.vessel"
              :flightNumber="dTracking.flight_number"
              :customer="dTracking.customer"
              :eta="dTracking.eta"
              :etd="dTracking.etd"
              :salesMarketing="dTracking.marketer"
              :status="dTracking.status_shipment"
              :idJobfile="dTracking.id"
            />
          </template>
        </template>
      </div>
      <div
        class="d-flex flex-column flex-md-row justify-content-between gap-2 gap-md-0"
      >
        <div
          class="d-flex flex-row gap-2 align-items-center justify-content-start"
        >
          <p class="font-roboto text-pagination">Show</p>
          <select
            class="form-select range-date"
            aria-label="Default select example"
            v-model="perPageString"
            @change="changePerPage"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>

          <p class="font-roboto text-pagination">Entries</p>
        </div>
        <div
          class="d-flex flex-column flex-md-row gap-2 align-items-center justify-content-start"
        >
          <p class="font-roboto text-pagination">
            Showing {{ from }} to {{ to }} of {{ total }} entries
          </p>
          <!-- btn-info -->
          <button
            class="btn custom-button align-self-stretch"
            @click="prevPage"
            :disabled="isPrevButtonDisabled"
          >
            <i class="bi bi-chevron-left me-2"></i
            ><span class="text-nowrap">Prev</span>
          </button>
          <button
            class="btn custom-button align-self-stretch"
            @click="nextPage"
            :disabled="isNextButtonDisabled"
          >
            <span class="text-nowrap">Next</span
            ><i class="bi bi-chevron-right ms-2"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- /TABLES -->
    <MainFooter />
  </section>
</template>

<script>
import Header from "@/components/general/Header.vue";
import MenuGroup from "@/components/general/MenuGroup.vue";
import DeliveryOrder from "@/components/dashboard/DeliveryOrder.vue";
// import dataTracking from "@/assets/json/tracking/dataTracking.json";
import MainFooter from "@/components/general/MainFooter.vue";

import { mapState, mapActions, mapGetters } from "vuex";
// import { Vuetable, VuetablePagination } from "vuetable-2";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";

import axios from "axios";

export default {
  name: "TrackingView",
  components: {
    Vuetable,
    VuetablePagination,
    Header,
    MenuGroup,
    DeliveryOrder,
    MainFooter,
  },
  data() {
    return {
      // search_tracking: "",
      // search_tracking_val: "",
      // rangeMonth: "january",
      // rangeYear: "2022",
      // optionsRangeMonth: [
      //   { text: "January", value: "january" },
      //   { text: "Febuary", value: "febuary" },
      //   { text: "March", value: "march" },
      //   { text: "April", value: "april" },
      //   { text: "Mei", value: "january" },
      //   { text: "Juny", value: "january" },
      //   { text: "July", value: "january" },
      //   { text: "August", value: "january" },
      //   { text: "September", value: "january" },
      //   { text: "October", value: "january" },
      //   { text: "November", value: "january" },
      //   { text: "December", value: "january" },
      // ],
      // optionsRangeYear: [
      //   { text: "2022", value: "2022" },
      //   { text: "2021", value: "2021" },
      //   { text: "2020", value: "2020" },
      // ],
      shipmentDataTracking: [],
      isNextButtonDisabled: false,
      isPrevButtonDisabled: true,
      perPage: 10,
      perPageString: "10",
      currentPage: 1,
      totalPage: 0,
      from: 1,
      to: 10,
      total: 0,
      searchQuery: "",
      selectedMonth: "",
      selectedYear: "",
      etaData: null,
      yearData: [],
    };
  },

  async mounted() {
    this.generateYearData();
    await this.fetchShipmentData(1, this.perPageString);

    try {
      const responseMonth = await axios.get(
       `/shipment-access-user/get-shipment-tracking-month?id=${this.$store.state.userLogin.id}`
      );
      this.etaData = responseMonth.data;
      // console.log(this.etaData);
      // console.log(this.yearData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },

  computed: {
    ...mapState(["userLogin"]),

    // filteredDataTracking() {
    //   return this.dataTracking.filter((result) => {
    //     return result.jobNumber
    //       .toLowerCase()
    //       .includes(this.search_tracking_val.toLowerCase());
    //   });
    // },
    // getLengthDataTracking() {
    //   return this.dataTracking.length;
    // },
  },

  methods: {
    generateYearData() {
      const currentYear = new Date().getFullYear();
      for (let i = 0; i <= 5; i++) {
        this.yearData.push(currentYear - i);
      }
    },
    async filterData() {
      await this.fetchShipmentData(1, this.perPageString);
    },

    async prevPage() {
      // console.log("Prev button clicked");
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchShipmentData(this.currentPage, this.perPageString);
      }
    },
    async nextPage() {
      this.currentPage++;
      // console.log(`Next button clicked:  ${this.currentPage}`);
      await this.fetchShipmentData(this.currentPage, this.perPageString);
    },
    async fetchShipmentData(page, perPage) {
      try {
        const response = await axios.get(
          `/shipment-access-user/get-shipment-tracking?id=${this.$store.state.userLogin.id}&page=${page}&per_page=${perPage}&month=${this.selectedMonth}&year=${this.selectedYear}&searchQuery=${this.searchQuery}`
        );
        if (response) {
          this.shipmentDataTracking = response.data.data;
          this.from = response.data.from;
          this.to = response.data.to;
          this.total = response.data.total;
          this.currentPage = response.data.current_page;
          this.isPrevButtonDisabled = this.currentPage === 1;
          this.isNextButtonDisabled =
            this.to === this.total || this.shipmentDataTracking.length === 0;
          // console.log('Tracking vue console',this.shipmentDataTracking);
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
      }
    },

    onRowClass() {
      return "custom-row-class";
    },

    goToDetailOrder(id) {
      this.$router.push(`/Tracking/${id}`);
    },
    
    applySearch() {
      this.filterData();
    },

    selectMonth(month) {
      this.selectedMonth = month;
      this.filterData();
    },

    selectYear(year) {
      this.selectedYear = year;
      this.filterData();
    },

    changePerPage() {
      this.fetchShipmentData(1, this.perPageString);
      // console.log("Selected Per Page:", this.perPageString);
    },

  },
};
</script>

<style>
table.vuetable thead {
  display: none;
}

table.vuetable tbody.vuetable-body tr.custom-row-class {
  --bs-table-bg: #fafafa !important;
  border-top: solid 2px #ebebeb;
}

.img-fluid.img-table-icon {
  width: 28px !important;
  height: 28px !important;
}

.badge.bg-success {
  height: 35px !important;
  font-size: 12px;
  color: #28ae96;
  background-color: #d4fae5 !important;
  width: 164px;
}
@media (max-width: 576px) {
  .badge.bg-costum {
    height: 100% !important;
    font-size: 12px;
    width: 164px !important;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .badge.bg-costum {
    height: 100% !important;
    font-size: 12px;
    width: 164px !important;
  }
}

.badge.bg-costum {
  height: 35px !important;
  font-size: 12px;
  width: 164px;
}

.title-tracking {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
}
.text-pagination {
  font-weight: 400;
  font-size: 12px;
  color: #565656;
}

/* btn-info => Next / Prev btn */
.custom-button {
  background-color: #017283 !important;
  box-shadow: 2px 2px 10px 2px rgba(81, 193, 173, 0.2);
  border-radius: 3px;
  color: #ffffff !important;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
}
.custom-button:hover {
  opacity: 0.8;
  background-color: #017283 !important;
  box-shadow: 2px 2px 10px 2px rgba(81, 193, 173, 0.2) !important;
  border-radius: 3px;
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  border: transparent;
}
/* select option */
.form-select.range-date {
  background-color: #28ae96 !important;
  border: 1px solid transparent;
  color: #ffffff !important;
  width: fit-content !important;
  background-image: url("../assets/images/down-icon.svg") !important;
}
.form-select.range-date option {
  width: fit-content !important;
  color: #ffffff !important;
}
.search-tracking input:focus {
  box-shadow: none !important;
}
::placeholder {
  font-size: 0.813rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.summary-content p {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #aeaeae;
}
.summary-content h6 {
  font-size: 0.9rem;
  color: #565656;
}
</style>
