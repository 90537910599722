<template>
  <section class="container-fluid d-flex flex-column">
    <Header />
    <span class="mb-4"></span>
    <MenuGroup activeMenu="tracking" />
    <!-- tracking status -->
    <div class="container mx-auto row bg-white px-3 px-md-4 py-3 rounded gap-4">
      <GeneralInformation :generalInformation="dataShipmentInfo" />
      <InformationTracking
        :number="
          dataShipmentInfo &&
          (dataShipmentInfo.bl_number || dataShipmentInfo.hawb_number)
            ? dataShipmentInfo.bl_number || dataShipmentInfo.hawb_number
            : '-'
        "
        :date="dataShipmentInfo ? dataShipmentInfo.date_received : null"
        :status="
          dataShipmentInfo && dataShipmentInfo.status
            ? dataShipmentInfo.status
            : null
        "
      />
      <TrackingStatus
        :trackingStatus="
          dataShipmentInfo ? dataShipmentInfo.shipment_timeline : null
        "
      />
      <TrackingInfo
        :key="dataShipmentInfo.id_jobfile"
        @refresh-request="refreshTrackingInfo"
      />
    </div>
    <!-- /tracking status -->
    <MainFooter />
  </section>
</template>

<script>
import Header from "@/components/general/Header.vue";
import MenuGroup from "@/components/general/MenuGroup.vue";
import MainFooter from "@/components/general/MainFooter.vue";
import TrackingStatus from "@/components/Tracking/TrackingStatus.vue";
import InformationTracking from "@/components/Tracking/InformationTracking.vue";
import GeneralInformation from "@/components/Tracking/GeneralInformation.vue";
import TrackingInfo from "@/components/Tracking/TrackingInfo.vue";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "TrackingView",
  components: {
    Header,
    MenuGroup,
    MainFooter,
    TrackingStatus,
    InformationTracking,
    GeneralInformation,
    TrackingInfo,
  },
  data() {
    return {
      dataTracking: {
        number: "dfdfddf",
        date: "12 Aug 2022 09:00 WIB",
      },
      dataShipmentInfo: {
        // t_jobfile_info
        id_jobfile: "",
        eta: "",
        shipper: "",
        consignee: "",
        notify_party: "",
        si_number: "",
        receipt: 0,
        loading: 0,
        delivery: 0,
        discharge: 0,
        agent_name: "",
        freight: 0,
        stuffing: "",
        do_number: "",
        volume: "",
        weight: "",
        desc_of_good: "",
        package: "",
        package_type: "",
        aju: "",
        incoterms: "",

        // t_jobfile_info_sea
        id_sea: null,
        bl_number: "",
        vessel: 0,
        mbl_number: "",
        hbl_number: "",
        id_shipping_line: 0,
        connecting_vessel: "",
        shipping_marks: "",
        issued_office: "",
        notes: "",
        booking_number: "",
        voyage_number: "",
        connecting_etd: "",

        // t_jobfile_info_air
        id_airlines: "",
        flight_number: "",
        connecting_etd: "",
        connecting_flight_number: "",
        hawb_number: "",
        mawb_number: "",
        iata_code: "",

        //  Shipment timeline
        shipment_timeline: [],
        status_shipment: [],
      },
    };
  },

  mounted() {
    this.getData();
    this.$on("refresh-tracking-info", this.getData);
    // console.log(this.dataShipmentInfo)
  },

  methods: {
    ...mapActions("shipmentTrackingInfo", ["getShipmentTrackingInfo"]),

    refreshTrackingInfo() {
      this.getData();
    },

    async getData() {
      const jobfileId = this.$route.params.jobfileId;
      this.dataShipmentInfo = await this.getShipmentTrackingInfo(jobfileId);
      // console.log("Detail Tracking Console", this.dataShipmentInfo);
    },

    beforeDestroy() {
      this.$off("refresh-tracking-info", this.getData);
    },
  },
};
</script>

<style scoped>
/* general information */
.general-information button.btn-warning {
  color: #ffffff;
  background-color: #f9a707;
  font-weight: 500;
  font-size: 12px;
}
.general-information button.btn-warning:hover {
  color: #ffffff;
  background-color: #f9a707;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.8;
}
.col-sm-12.general-information {
  background: #fff2d9;
}
.img-fluild.general-information-img {
  width: 46px;
}
.general-information-text p {
  font-size: 12px;
  color: #585858;
  font-weight: 700;
}
.general-information-text p.text-muted {
  font-size: 12px;
  color: #817f7f;
  font-weight: 400;
}
/* select option */
.form-select {
  background-color: #28ae96 !important;
  border: 1px solid transparent;
  color: #ffffff !important;
  width: fit-content !important;
  background-image: url("../assets/images/down-icon.svg") !important;
}
.form-select option {
  width: fit-content !important;
  color: #ffffff !important;
}
.search-tracking input:focus {
  box-shadow: none !important;
}
::placeholder {
  font-size: 0.813rem;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
</style>
