// import state from "./states";
// import * as actions from "./actions";
// import * as mutations from "./mutations";
// import * as getters from "./getters";
// import Auth from "../../../api/Auth";
import axios from 'axios'

export const state = {
    city: [],
}
export const mutations = {
    set_city (state, payload) {
        state.city = payload
    },
    setError: (state, payload) => {
        state.errors = payload
    }
}
export const actions = { 
    getCity: async (context, payload, getters) => {
        try {
            let {data} = await axios.get('/city')
            
            context.commit('set_city', data)
            return data
        } 
        catch (error) {
            console.log(error)
            context.commit('setError', error)
        }
    },
}
export const getters = { 
    
}

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations
};