<template>
    <section class="container mx-auto row px-0 py-4 bg-white rounded gap-4 gap-md-0">
        <template v-if="!isEdited">
            <div class="col-sm-3 d-flex align-items-center justify-content-center">
                <img :src="previewImage" class="img-fluid img-profile" />
            </div>
            <div class="col-sm-9 row">
                <CardSummary imgUrl="profile-fullname.svg" context="Full Name:" :value="dataProfiles.fullname" textColor="#017283" />
                <CardSummary imgUrl="profile-email.svg" context="Email:" :value="this.$store.state.userLogin.email" textColor="#017283" />
                <CardSummary imgUrl="profile-expired.svg" context="Account Expired:" :value="dataProfiles.accountExpired" textColor="#FF6D6D" />
                <CardSummary imgUrl="profile-phone.svg" context="Phone Number:" :value="dataProfiles.phoneNumber" textColor="#017283" />
                <CardSummary imgUrl="profile-country.svg" context="Country:" :value="dataProfiles.country" textColor="#017283" />
                <CardSummary imgUrl="profile-city.svg" context="City:" :value="dataProfiles.city" textColor="#017283" />
                <CardSummary imgUrl="profile-address.svg" context="Address:" :value="dataProfiles.address" textColor="#017283" />
                <div class="col-sm-12 d-flex justify-content-center justify-content-md-end align-items-center">
                    <button class="btn btn-success font-roboto px-5" @click="gotoEdit()">Edit</button>
                </div>
            </div>
        </template>
        <template v-else>            
            <FormProfile />            
        </template>
    </section>
    <!-- <div>
        <div class="content-page">
            <div class="content">
                <div class="">
                    <div class="row d-flex">
                        <div class="col-lg-3">
                            <div
                                class="imagePreviewWrapper"
                                :style="{ 'background-image': `url(${previewImage})` }">
                            </div>
                        </div>
                        <div class="row col-lg-9">
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-user.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column ml-1 col-lg-8">
                                        <div class="mini-menu-label">Fullname</div>
                                        <div class="menu-label-inside">{{ dataProfiles.fullname }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-email.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">Email</div>
                                        <div class="menu-label-inside">{{ dataProfiles.email }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-date.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">Account Expired </div>
                                        <div class="menu-label-inside" style="color: #ff8080">{{ dataProfiles.accountExpired }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-phone.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">Phone number</div>
                                        <div class="menu-label-inside">{{ dataProfiles.phoneNumber }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-world.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">Country</div>
                                        <div class="menu-label-inside"> {{ dataProfiles.country }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-city.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">City</div>
                                        <div class="menu-label-inside">{{ dataProfiles.city }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 wrapper-item">
                                <div class="row d-flex details">
                                    <div class="col-lg-3">
                                        <img src="../../assets/media/svg/profile/icon-address.svg" alt="">
                                    </div>
                                    <div class="d-flex flex-column col-lg-8 ml-1">
                                        <div class="mini-menu-label">Address</div>
                                        <div class="menu-label-inside">{{ dataProfiles.address }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-edit">
                        <button type="button" class="btn btn-edit" @click="gotoEdit">Edit</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    
</template>

<script>
// import ProfileView from './ProfileView.vue'
// import DashboardWidget from "@/components/DashboardWidget.vue";
import dataProfile from "@/assets/json/profile/dataProfile.json"
import CardSummary from "@/components/general/summary/CardSummary.vue"
import FormProfile from "@/components/form/FormProfile.vue"
import { mapActions, mapState, mapGetters } from "vuex";
import moment from 'moment'

export default {
    name: "ProfileComp",
    components: {
        // ProfileView,
        // DashboardWidget,
        CardSummary,
        FormProfile
    },
    data() {
        return {
            dataProfiles: {
                fullname: '',
                email: '',
                accountExpired: '',
                phoneNumber: '',
                country: '',
                city: '',
                address: '',
            },
            isEdited: false,
            message: "Edit Your Profile",
            modules: "Profile",
            icon: `${require('../../assets/media/svg/icon-user.svg')}`,
            previewImage: `${require('../../assets/media/svg/profile/image-placeholder.svg')}`,
        }
    },
    mounted(){
        this.dataProfile()
    },

    computed: {
        ...mapState(["userLogin"]),
    },

    methods: {
        ...mapActions('profile', ['getProfile']),

        gotoEdit(){
            // this.$router.replace('/profile/edit')
            this.isEdited = !this.isEdited
        },

        dataProfile(){
            this.getProfile(this.userLogin.id)
                .then((res) => {
                    if(res){
                        this.dataProfiles.email = res.email
                        this.dataProfiles.fullname = res.fullname
                        this.dataProfiles.phoneNumber = res.phone_number
                        this.dataProfiles.accountExpired = moment(res.shipment_access_user.shipment_access.expired_date).format('DD-MM-YYYY')
                        this.dataProfiles.address = res.address
                        if(res.city !== null){
                            this.dataProfiles.city = res.city.name
                        }
                        if(res.country !== null){
                            this.dataProfiles.country = res.country.name
                        }
                        if(res.image){
                            this.previewImage = `data:image/jpeg;base64,${res.image}`
                        } 
                    }
                })
        }
    }
}
</script>

<style>
    .menu-label-inside{
        /* margin: 0px 20px 0px 20px; */
        color: #585858;
        font-weight: bold;
        font-size: 16px;
        word-wrap: break-word;
    }
    .mini-menu-label{
        /* margin: 0px 20px 0px 20px; */
        color: #9C9C9C;
        font-weight: 400;
        font-size: 12px
    }

    .image-profile{
        border-radius: 30px;
        max-width: 189px;
        max-height: 189px;
    }

    .img-fluid.img-profile {
        width: 11.875rem;
        height: 11.875rem;
        object-fit: cover;
        background-size: cover;
        border-radius: 1rem;
    }

    .wrapper-item{
        padding: 0
    }

    .btn-edit {
        width: 119px;
        color: white !important;
        background-color: #28AE96 !important;
        cursor: pointer;
    }

    .btn-edit:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
        color: white !important;
        background-color: #267e6e !important;
        border-color:#93e8d9 !important
    }

    .imagePreviewWrapper {
        width: 198px;
        height: 198px;
        display: block;
        cursor: pointer;
        margin: 0 auto 30px;
        background-size: cover;
        background-position: center center;
        border-radius: 30px;
        /* position: absolute;
        top: 17px;
        left: 10px; */
    }

    .button-edit{
        float: right !important;
    }

    .res {
        display: none;
    }

    @media screen and (max-width: 988px) {
		.wrapper-item{
            text-align: center;
        }

        .details{
            display:flex ;
            flex-direction: row !important;
            margin-bottom: 10px;
            padding: 0px
            /* justify-content: center !important; */
        }

        .details > div:nth-child(1){
            width: 15%;
            text-align: left;
        }
        .details > div:nth-child(2){
            width: 75%;
            text-align: left;
        }
        .button-edit{
            float: none !important;
            text-align: center;
            margin-top: 20px;
        }

        .res{
            display: flex;
        }
	}
</style>