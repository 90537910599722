<template>
    <div class="col-sm-12 mt-2">
        <div class="card border-0 p-2 shadow-sm card-notificationn-wrapper">
            <div class="d-flex flex-row justify-content-center align-items-center card-notification-content position-relative">                
                <button class="btn btn-secondary close-notification btn-sm bg-transparent position-absolute top-0 end-0">
                    <i class="bi bi-x"></i>
                </button>
                <div class="card-body d-flex gap-3 align-items-center px-0 py-2"
                    :class="{'notification-readed' : isReaded}"
                >
                    <div class="border-notification"></div>
                    <template v-if="status === 'scheduled'">
                        <div class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification">
                            <i class="bi bi-calendar-week"></i>
                        </div>
                    </template>
                    <template v-else-if="status === 'finished'">
                        <div class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification">
                            <i class="bi bi-check-circle-fill"></i>
                        </div>
                    </template>
                    <template v-else-if="status === 'packed'">
                        <div class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification">
                            <img src="@/assets/images/box-open.svg" width="16" />
                        </div>
                    </template>
                    <template v-else-if="status === 'port'">
                        <div class="rounded-circle d-flex justify-content-center align-items-center align-self-baseline my-auto icon-content-notification">
                            <img src="@/assets/images/dolly-solid.svg" width="16" />
                        </div>
                    </template>
                    <div class="d-flex flex-column gap-1 notification-text">
                        <h5 class="card-title">{{ title }}</h5>
                        <p class="card-text" v-html="desc"></p>
                    </div>
                </div>
                <span class="time-notification position-absolute bottom-0 end-0">{{time}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            desc: String,
            status: String,
            time: String,
            isReaded: Boolean,
        }
    }
</script>

<style scoped>
    /* mobile */
    @media (min-width: 300px) {
        .card-notificationn-wrapper {
            width: fit-content !important;
        }
        .card div.card-notification-content .card-body h5.card-title {
            color: #28AE96;
            font-size: 12px;
        }

        .card div.card-notification-content .card-body p.card-text {
            color: #565656;
            font-size: 10px;
        }
        .time-notification {
            font-size: 0.6rem;
        }
        .sub-title-notification, .time-notification {
            color: #565656;
        }
    }
    /* tablet / horizontal mobile */
    @media (min-width: 768px) { 
        .card div.card-notification-content .card-body h5.card-title {
            color: #28AE96;
            font-size: 1.1rem;
        }

        .card div.card-notification-content .card-body p.card-text {
            color: #565656;
            font-size: 0.9rem;
        }

        .time-notification {
            font-size: 0.8rem;
        }
        .sub-title-notification, .time-notification {
            color: #565656;
        }
    }
    /* desktop */
    @media (min-width: 992px) { 
        .card-notificationn-wrapper {
            width: 100% !important;
        }        
    }
    .notification-text h5 {
        font-size: 8px;
    }    
    .notification-readed .border-notification {
        background-color: #B0B0B0;
        width: 6px !important;
        height: 80px !important;
        border-radius: 8px;
    }   

    .notification-text {
        width: 85% !important;
    }
    
    .btn.btn-secondary.close-notification {
        padding: 0;
        margin-right: -8px;
        color: #FF6D6D;
        border: transparent;
        font-size: 1.5rem !important;
    }

    .btn.btn-secondary.close-notification:hover {
        opacity: 0.8;
        padding: 0;
        margin-right: -4px;
        color: #FF6D6D;
        border: transparent;
        font-size: 1.5rem !important;
    }

    .card-notification.content .btn-close {
        width: 0.9rem !important;
        height: 0.9rem !important;
    }
    .border-notification {
        background-color: #28AE96;
        width: 6px !important;
        height: 80px !important;
        border-radius: 8px;        
    }
    .card div.card-notifiocation.content .border-start {
        border-left: 6px solid #28AE96 !important;
        border-radius: 8px;
    }    
    
    .notification-content h6 {
        color: #28AE96;
        font-size: 1.2rem;
    }

    .icon-content-notification {
        width: 30px;
        height: 30px;
        background-color: #E3F3F0;
        color: #28AE96;
    }
</style>