import axios from "axios";

export const state = {
  dailyDeliveryOrder: [],
};

export const mutations = {
  setDailyDeliveryOrder(state, payload) {
    state.dailyDeliveryOrder = payload;
  },

  setError(state, payload) {
    state.errors = payload;
  },
};

export const actions = {
  getDailyDeliveryOrder: async (context, payload, getters) => {
    try {
      let { data } = await axios.get(
        "/dashboard-tracking/get-daily-delivery-order?id=" + payload
      );
      context.commit("setDailyDeliveryOrder", data);
      return data;
    } catch (error) {
      console.log(error);
      context.commit("setError", error);
    }
  },
};

export const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
