<template>
  <!-- card delivery order -->
  <div>
    <div
      class="card-order border rounded d-md-flex gap-2 flex-nowrap justify-content-around align-items-center px-3 d-none"
    >
      <div class="">
        <img
          src="@/assets/images/icon-box.png"
          alt="attribute"
          class="align-self-center img-fluid img-table-icon d-none d-md-block"
        />
      </div>
      <div class="col-1 summary-content">
        <p class="font-poppins mb-1">
          {{ id_sea ? "BL Number :" : "HAWB Number :" }}
        </p>
        <h6 class="fw-bold">{{ blNumber || hawbNumber || "-" }}</h6>
      </div>
      <div class="col-md-2 col-1 summary-content">
        <p class="font-poppins mb-1">Job Number</p>
        <h6 class="fw-bold">{{ jobNumber ? jobNumber : "-" }}</h6>
      </div>
      <div class="col-1 summary-content">
        <p class="font-poppins mb-1">Customer :</p>
        <h6 class="fw-bold">{{ customer ? customer : "-" }}</h6>
      </div>
      <div class="col-1 summary-content">
        <p class="font-poppins mb-1">
          {{ id_sea ? "Vessel :" : "Flight Number :" }}
        </p>
        <h6 class="fw-bold">{{ vessel || flightNumber || "-" }}</h6>
      </div>
      <div class="col-1 summary-content">
        <p class="font-poppins mb-1">ETA :</p>
        <h6 class="fw-bold">{{ eta ? eta : "-" }}</h6>
      </div>
      <div class="col-1 summary-content">
        <p class="font-poppins mb-1">ETD :</p>
        <h6 class="fw-bold">{{ etd ? etd : "-" }}</h6>
      </div>
      <div class="col-2 summary-content mb-1">
        <p class="font-poppins mb-1">Sales Marketing</p>
        <h6 class="fw-bold">{{ salesMarketing ? salesMarketing : "-" }}</h6>
      </div>
      <div class="">
        <span
          v-if="status === null"
          class="font-roboto badge bg-costum d-none d-md-flex justify-content-center px-4 py-3 py-md-0 d-md-flex align-items-center"
          :style="{ backgroundColor: '#276cb8' }"
          >Proses Penjadwalan</span
        >

        <span
          v-if="status && status.type === 1 && status.status_shipment"
          class="font-roboto badge bg-costum d-none d-md-flex justify-content-center px-4 py-3 py-md-0 d-md-flex align-items-center"
          :style="{
            backgroundColor: status
              ? status.status_shipment.status_color
              : '#276cb8',
          }"
          >{{
            status ? status.status_shipment.title : "Sedang di proses"
          }}</span
        >

        <span
          v-if="status && status.type === 2"
          class="font-roboto badge bg-costum d-none d-md-flex px-4 py-3 py-md-0 d-md-flex align-items-center gap-4"
          :style="{ backgroundColor: '#f9a706' }"
        >
          <div class="rounded-circle" style="background-color: #e7f2e7">
            <img
              src="@/assets/images/verified-icon.svg"
              class="img-fluid verified"
            />
          </div>
          Finished</span
        >
      </div>
      <div class="">
        <button
          class="btn btn-warning table btn-sm p-2 m-0 d-none d-md-block"
          @click="goToDetailOrder(idJobfile)"
        >
          <i class="bi bi-geo-alt-fill"></i>
        </button>
      </div>
    </div>

    <!-- Mobile -->
    <div
      class="card-order d-flex border flex-column flex-md-row px-0 px-md-2 py-1 py-md-3 rounded d-md-none"
    >
      <div class="d-flex gap-4 py-2 px-1 px-md-4 overflow-auto flex-nowrap">
        <img
          src="@/assets/images/icon-box.png"
          alt="attribute"
          class="align-self-start img-fluid img-table-icon d-block d-md-none"
        />

        <div class="d-flex flex-column gap-2">
          <div
            class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
          >
            <p>{{ id_sea ? "BL Number :" : "HAWB Number :" }}</p>
            <h6 class="fw-bold">{{ blNumber || hawbNumber || "-" }}</h6>
          </div>
          <span
            v-if="status === null"
            class="font-roboto badge bg-costum d-flex justify-content-center align-items-center px-2 d-block d-md-none"
            :style="{ backgroundColor: '#276cb8' }"
          >
            Proses Penjadwalan
          </span>
          <span
            v-if="status && status.type === 1 && status.status_shipment"
            class="font-roboto badge bg-costum d-flex justify-content-center align-items-center px-2 d-block d-md-none"
            :style="{
              backgroundColor: status
                ? status.status_shipment.status_color
                : '#276cb8',
            }"
          >
            {{ status ? status.status_shipment.title : "Sedang di proses" }}
          </span>
          <span
            v-if="status && status.type === 2"
            class="font-roboto badge bg-costum d-flex justify-content-center align-items-center px-2 d-block d-md-none"
            :style="{ backgroundColor: '#f9a706' }"
          >
            Finished
          </span>
        </div>

        <button
          class="btn btn-warning table btn-sm p-2 m-0 d-block d-md-none align-self-start"
          @click="goToDetailOrder(idJobfile)"
        >
          <i class="bi bi-geo-alt-fill"></i>
        </button>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>Job Number</p>
          <h6 class="fw-bold">{{ jobNumber ? jobNumber : "-" }}</h6>
        </div>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>Customer</p>
          <h6 class="fw-bold">{{ customer ? customer : "-" }}</h6>
        </div>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>
            {{ id_sea ? "Vessel :" : "Flight Number :" }}
          </p>
          <h6 class="fw-bold">{{ vessel || flightNumber || "-" }}</h6>
        </div>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>ETA</p>
          <h6 class="fw-bold">{{ eta ? eta : "-" }}</h6>
        </div>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>ETD</p>
          <h6 class="fw-bold">{{ etd ? etd : "-" }}</h6>
        </div>

        <div
          class="d-md-none flex-column gap-1 align-self-start summary-content d-flex"
        >
          <p>Sales Marketing</p>
          <h6 class="fw-bold">{{ salesMarketing ? salesMarketing : "-" }}</h6>
        </div>
      </div>
    </div>
  </div>

  <!-- /card delivery order -->
</template>

<script>
export default {
  props: {
    idJobfile: Number,
    id_sea: Number,
    id_info: Number,
    jobNumber: String,
    blNumber: String,
    hawbNumber: String,
    customer: String,
    vessel: String,
    flightNumber: String,
    eta: String,
    etd: String,
    salesMarketing: String,
    status: Object,
    // isTracking: Boolean,
    // idOrder: String,
  },
  methods: {
    goToDetailOrder(idJobfile) {
      this.$router.push(`/Tracking/${idJobfile}`);
    },
  },
};
</script>

<style>
/* Small devices (landscape phones, 576px and up) */
/* @media (max-width: 576px) {
  .badge.bg-success {
    height: 100% !important;
    font-size: 12px;
    color: #28ae96;
    background-color: #d4fae5 !important;
    width: 164px !important;
  }
} */
/* Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) {
  .badge.bg-success {
    height: 100% !important;
    font-size: 12px;
    color: #28ae96;
    background-color: #d4fae5 !important;
    width: 164px !important;
  }
} */

@media (max-width: 576px) {
  .badge.bg-costum {
    height: 100% !important;
    font-size: 12px;
    color: #fff;
    width: 164px !important;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .badge.bg-costum {
    height: 100% !important;
    font-size: 12px;
    color: #fff;
    width: 164px !important;
  }
}

.badge.bg-costum {
  height: 35px !important;
  font-size: 12px;
  color: #fff;
  width: 164px;
}

/* CARD ORDER TABLE */
.img-fluid.img-table-icon {
  width: 28px !important;
  height: 28px !important;
}
/* .badge.bg-success {
  height: 35px !important;
  font-size: 12px;
  color: #28ae96;
  background-color: #d4fae5 !important;
  width: 164px;
} */
.btn.btn-warning.table {
  width: 39px !important;
  color: #f9a707;
  background-color: #fddfa4;
  font-size: 0.9rem;
  border: 1px solid transparent;
}
.btn.btn-warning.table:hover {
  opacity: 0.8;
  width: 39px !important;
  color: #f9a707;
  background-color: #fddfa4;
  font-size: 0.9rem;
  border: 1px solid transparent;
}
.card-order {
  background-color: #fafafa;
}
.img-fluid.img-table-icon {
  width: 26px;
  height: 26px;
}
.btn.btn-success.table {
  width: 240px;
}
.btn.btn-warning.table {
  width: fit-content;
}
.summary-content p {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #aeaeae;
}
.summary-content h6 {
  font-size: 0.9rem;
  color: #565656;
}

.img-fluid.verified {
  width: 24px;
  padding: 5px;
}
</style>
