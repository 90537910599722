// import state from "./states";
// import * as actions from "./actions";
// import * as mutations from "./mutations";
// import * as getters from "./getters";
// import Auth from "../../../api/Auth";
import axios from 'axios'

// const auth = {
    export const state = {
        user: null,
        isLoggedIn: false,
        isExpiredLink: [],
        userLogins: []
    }
    export const mutations = {
        set_user (state, payload) {
            state.user = payload
            state.isLoggedIn = true
        },
        set_user_login (state, payload) {
            state.userLogins = payload
        },
        reset_user (state, payload) {
            state.user = null
            state.isLoggedIn = false
        },
        setExpired (state, payload) {
            state.isExpiredLink = payload
        },
        setError: (state, payload) => {
            state.errors = payload
        }
    }
    export const actions = { 
        login: async (context, payload, getters) => {
            try {
                let {data} = await axios.post('/login', payload)
                localStorage.setItem('token', data.token)
                saveState('auth.currentUser', data.user)
                
                context.commit('set_user', data)
                return data
            } 
            catch (error) {
                console.log(error)
                context.commit('setError', error)
            }
        },
        recoverPassword: async (context, payload) => {
            try {
                let {data} = await axios.post('/recover-password', {email: payload})
                return data
            } 
            catch (error) {
                console.log(error)
                context.commit('setError', error)
            }
        },

        checkLinkForgotPass: async (context, payload) => {
            try {
                let {data} = await axios.get('/is-link-expired?email=' + payload.email + '&token=' + payload.token)
                return data;
                // context.commit('setExpired', payload)
            } catch (error) {
                console.log(error)
                context.commit('setError', error)
            }
        },

        getLoginUser: async(context, payload) => {
            try {
                let {data} = await axios.get(`/user/` + payload)
                context.commit('set_user_login', data)
            } catch (error) {
                console.log(error)
                context.commit('setError', error)
            }
        }
    }
    export const getters = { 
        isLoggedIn (state, payload)  {
            return state.isLoggedIn
        },
        users (state, payload)  {
            return state.user
        }
     }
// }
function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state))
}

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations
};