import axios from "axios";
import app from "../main";
import router from "@/router";
import Swal from "sweetalert2";

window.axios = axios
/** baseUrl Local */
// axios.defaults.baseURL = 'http://localhost:8000/api/'

/** baseURL Dummy or Devlop*/
// axios.defaults.baseURL = 'https://api-dummy-shipments.rimbahouse.com/api/'

/** baseURL Production */
axios.defaults.baseURL = 'https://tracking-oaktree-api.rimbahouse.id/api/'

window.axios.defaults.headers["Access-Control-Allow-Origin"] = "*";



// export function setHeaderToken (token) { 
//   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
// }

// export function removeHeaderToken () {
//   delete axios.defaults.headers.common['Authorization']
// }
// window.axios.defaults.headers.common = {
//   Authorization: "Bearer " + localStorage.getItem("token"),
// };


